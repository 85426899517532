import axios from "axios";
let url = "";

if (process.env.REACT_APP_EMBEDDED === 'true') {
    url = `http://${window.location.hostname}:8001`;
} else if (process.env.REACT_APP_ENV === "production") {
    url = `https://mle-mqtt-logger-api.ie-concept.com`;
} else if (process.env.REACT_APP_ENV === "develop") {
    url = `https://mle-mqtt-logger-api-develop.ie-concept.com`;
} else {
    url = `http://${window.location.hostname}:8001`;
}

// AXIOS INIT
let axiosInstance = axios.create({
    baseURL: url,
    responseType: "json",
});

export default axiosInstance;
