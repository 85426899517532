import socketIOClient from 'socket.io-client';

function loggerWebsocket(msg, obj) {
    //---
    if (typeof msg == 'string') {
        console.log(' [ Websocket ] : ' + msg);
        if (obj) console.log(obj);
    } else {
        console.log(' [ Websocket ] : ');
        console.log(msg);
    }
}
/**
 * Object WebsocketService
 *
 * @param {*} url_ : url for connect websocket
 */
class WebsocketService {
    constructor(url_) {
        this.socket = null;
        this.rooms = [];
        this.onEvents = [];
        this.url = url_;
        this.timeout = 500; // Initial timeout duration as a class variable
        this.firstConnection = 1;
        this.connections = [];
        this.disconnections = [];
        //--- Init
        this.connect();
        this.offEvent = this.offEvent.bind(this);
    }

    onConnected(callback) {
        if (callback) {
            this.connections.push(callback);
        }
    }

    onDisconnected(callback) {
        if (callback) {
            this.disconnections.push(callback);
        }
    }

    joinRoom(room) {
        //--- Check socket exist
        if (this.socket != null) {
            loggerWebsocket('Join Room : ' + room);
            this.socket.emit('action:join-room', room, response => loggerWebsocket(response));
        }
        //--- Push on FIFO roms
        this.rooms.push(room);
    }

    onEvent(event, callback) {
        //--- Check socket exist
        if (this.socket != null) {
            loggerWebsocket('onEvent : ' + event);
            this.socket.on(event, callback);
        }
        //--- Push on FIFO roms
        this.onEvents.push({
            event,
            callback,
        });
    }

    offEvent(event, callback) {
        if (this.socket != null) {
            if (callback) {
                this.socket.off(event, callback);
            } else {
                this.socket.off(event);
            }
        }
    }

    /**
     * @function connect
     * This function establishes the connect with the websocket and also ensures constant reconnection if connection closes
     */
    connect() {
        const options = {
            pingInterval: 30000,
            pingTimeout: 20000,
            upgradeTimeout: 30000,
        };
        //var ws = new WebSocket("ws://localhost:3000/");
        const socket = socketIOClient(this.url, options);
        const self = this; // cache the this
        let connectInterval;

        loggerWebsocket('test connect websocket ....');

        socket.on('connect', function () {
            loggerWebsocket('connected websocket main component');

            self.socket = socket;
            //--- join rooms
            if (self.rooms.length > 0) {
                for (const i in self.rooms) {
                    loggerWebsocket('join room : ' + self.rooms[i]);
                    //---
                    socket.emit('action:join-room', self.rooms[i], response => loggerWebsocket(response));
                }
            }
            //--- add events on FIFO
            //if (self.firstConnection) {
            self.firstConnection = false;
            if (self.onEvents.length > 0) {
                for (const i in self.onEvents) {
                    loggerWebsocket('Event : ' + self.onEvents[i].event);
                    //---
                    socket.on(self.onEvents[i].event, self.onEvents[i].callback);
                }
            }
            //}

            self.connections.forEach(element => {
                element();
            });

            self.timeout = 500; // reset timer to 250 on open of websocket connection
            clearTimeout(connectInterval); // clear Interval on on open of websocket connection
        });

        socket.on('exception', function (data) {
            console.error('event', data);
        });

        socket.on('disconnect', function (e) {
            loggerWebsocket(
                `Socket is closed. Reconnect will be attempted in ${Math.min(
                    10000 / 1000,
                    (self.timeout + self.timeout) / 1000
                )} second.`,
                e.reason
            );

            self.disconnections.forEach(element => {
                element();
            });
            //---
            self.socket.disconnect();
            self.socket = null;
            //--- clear
            self.timeout = self.timeout + self.timeout; //increment retry interval
            connectInterval = setTimeout(() => {
                //--- If no connect, retry
                if (!self.socket) self.connect();
            }, Math.min(10000, self.timeout)); //call check function after timeout
        });
    }
}

let websocket;

if (process.env.REACT_APP_CI === 'true') {
    websocket = new WebsocketService(`http://${process.env.REACT_APP_API_SERVICE}:3000`);
} else if (process.env.REACT_APP_EMBEDDED === 'true') {
    websocket = new WebsocketService(`http://${window.location.hostname}:8001`);
} else if (process.env.REACT_APP_ENV === 'production') {
    websocket = new WebsocketService('https://mle-supervision-api-v3.ie-concept.com');
} else if (process.env.REACT_APP_ENV === 'develop') {
    websocket = new WebsocketService('https://mle-supervision-api-v3-develop.ie-concept.com');
} else if (process.env.NODE_ENV === 'production') {
    websocket = new WebsocketService(`http://${window.location.hostname}:3000`);
} else if (process.env.REACT_APP_DEBUG === 'true') {
    websocket = new WebsocketService(`http://172.16.9.10:3000`);
} else if (process.env.REACT_APP_DEVELOP === 'true') {
    websocket = new WebsocketService(`http://172.16.9.11:3000`);
} else {
    websocket = new WebsocketService(`http://${window.location.hostname}:8001`);
}

export const webSocketService = {
    joinRoom,
    onEvent,
    offEvent,
    onConnected,
    onDisconnected,
};

function joinRoom(room) {
    return websocket.joinRoom(room);
}

function onEvent(event, callback) {
    return websocket.onEvent(event, callback);
}

function offEvent(event, callback) {
    return websocket.offEvent(event, callback);
}

function onConnected(callback) {
    return websocket.onConnected(callback);
}

function onDisconnected(callback) {
    return websocket.onDisconnected(callback);
}
