import React from "react";
import "./../style/style.css";

export default class Horaire extends React.Component {
    constructor(props) {
        super(props);
        this.state = { date: new Date() };
    }

    componentDidMount() {
        this.timerID = setInterval(() => this.tick(), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    tick() {
        this.setState({
            date: new Date(),
        });
    }

    render() {
        return (
            <h4>
                {this.state.date.toLocaleDateString()} -{" "}
                {this.state.date.toLocaleTimeString()}
            </h4>
        );
    }
}
