export const niveau = [
    {
        label: "Avertissement",
        value: "WARN",
    },
    {
        label: "Debug",
        value: "DEBUG",
    },
    {
        label: "Erreur",
        value: "ERROR",
    },
    {
        label: "Information",
        value: "INFO",
    },
    {
        label: "Silly",
        value: "SILLY",
    },
    {
        label: "Verbose",
        value: "VERBOSE",
    },
];

export const niveauModal = [
    {
        label: "Avertissement",
        value: "WARN",
    },
    {
        label: "Debug",
        value: "DEBUG",
    },
    {
        label: "Erreur",
        value: "ERROR",
    },
    {
        label: "Information",
        value: "INFO",
    },
    {
        label: "Silly",
        value: "SILLY",
    },
    {
        label: "Verbose",
        value: "VERBOSE",
    },
];

export const applicationNames = [
    {
        label: "api_supervisionV3",
        value: "api_supervisionV3",
    },
    {
        label: "engine_supervisionV3",
        value: "engine_supervisionV3",
    },
];

export const applicationNameModal = [
    {
        label: "api_supervisionV3",
        value: "api_supervisionV3",
    },
    {
        label: "engine_supervisionV3",
        value: "engine_supervisionV3",
    },
];

export const nbResult = [
    {
        label: 50,
        value: 50,
    },
    {
        label: 100,
        value: 100,
    },
    {
        label: 200,
        value: 200,
    },
    {
        label: 500,
        value: 500,
    },
    {
        label: 1000,
        value: 1000,
    },
];

export const conditionalRowStyles = [
    {
        when: (row) => row.level === "INFO",
        style: {
            color: "#2DDE25",
            background: "#303030",
        },
    },
    {
        when: (row) => row.level === "WARN",
        style: {
            color: "orange",
            background: "#303030",
        },
    },
    {
        when: (row) => row.level === "ERROR",
        style: {
            color: "red",
            background: "#303030",
        },
    },
    {
        when: (row) => row.level === "VERBOSE",
        style: {
            color: "#247CE0",
            background: "#303030",
        },
    },
    {
        when: (row) => row.level === "DEBUG",
        style: {
            color: "#B71CE4",
            background: "#303030",
        },
    },
    {
        when: (row) => row.level === "SILLY",
        style: {
            color: "#99A3A4",
            background: "#303030",
        },
    },
];

export const customStyles = {
    header: {
        style: {
            minHeight: "5px",
        },
    },

    headRow: {
        style: {
            minHeight: "40px",
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
        },
    },

    headCells: {
        style: {
            fontSize: "13px",
        },
    },

    rows: {
        style: {
            minHeight: "40px",
        },
    },
};
