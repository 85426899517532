import React, { Component } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Moment from "react-moment";
import { Button, Col, DatePicker, FlexboxGrid, Icon, IconButton, Input, InputPicker, Modal, TagPicker } from "rsuite";
import "rsuite/dist/styles/rsuite-default.css";
import { webSocketService } from '../services/websocket.services';
import "./../style/style.css";
import axiosInstance from "./axios";
import { applicationNameModal, applicationNames, conditionalRowStyles, customStyles, nbResult, niveau, niveauModal } from "./Consts";
import Horaire from "./Horaire";
// Set the output format for every react-moment instance.
Moment.globalFormat = "DD-MM-YYYY hh:mm:ss a";

const uiTimeOut = 500;
let refreshUi;
export default class Tableau extends Component {
    constructor(props) {
        super(props);

        this.log = [];
        this.state = {
            selectedNbResult: 100,
            selectedNiveau: [],
            selectedNiveauModal: [],
            selectedApplication: [],
            selectedApplicationModal: [],
            selectedUtilisateur: null,
            selectedAfter: null,
            selectedBefore: null,
            selectedContext: "all",
            data: [],
            userList: [],
            isFetching: true,
            hasError: false,
            show: false,
        };

        this.onChange = this.onChange.bind(this);
        this.onChangeResult = this.onChangeResult.bind(this);
        this.onChangeApplication = this.onChangeApplication.bind(this);
        this.onChangeApplicationModal = this.onChangeApplicationModal.bind(this);
        this.onChangeUtilisateur = this.onChangeUtilisateur.bind(this);
        this.onChangeAfter = this.onChangeAfter.bind(this);
        this.onChangeBefore = this.onChangeBefore.bind(this);
        this.onChangeLevelModal = this.onChangeLevelModal.bind(this);
        this.onChangeContext = this.onChangeContext.bind(this);
        this.newLog = this.newLog.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.putData = this.putData.bind(this);
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);

        this.columns = [
            {
                name: "Application",
                selector: "applicationName",
                compact: true,
                sortable: true,
            },
            {
                name: "Date",
                selector: "time",
                compact: true,
                sortable: true,
                cell: (row) => {
                    return <Moment>{row.time}</Moment>;
                },
            },
            {
                name: "Niveau",
                selector: "level",
                compact: true,
                sortable: true,
                grow: 0,
            },
            {
                name: "Utilisateur",
                selector: "user",
                compact: true,
                sortable: true,
            },
            {
                name: "Contexte",
                selector: "context",
                compact: true,
                sortable: true,
            },
            {
                name: "Tag",
                selector: "tag",
                compact: true,
                sortable: true,
            },
            {
                name: "Message",
                selector: "message",
                compact: true,
                sortable: true,
                grow: 3,
                cell: (row) => <div>{row.message}</div>
            },
        ];
        
    }

    componentDidMount() {
        this.fetchData();
        webSocketService.joinRoom('LogEvent');

        webSocketService.onEvent('event:newLog', this.newLog);
    }

    componentWillUnmount() {
        webSocketService.offEvent('event:newLog', this.newLog);
    }

    newLog(data) {  
        const updatingLog = [...this.log];
        updatingLog.unshift(data);
        this.log = updatingLog;
        if(!refreshUi){
            refreshUi = setTimeout(() => {
                this.setState({data: updatingLog}, () => {
                    refreshUi = null;
                });
            }, uiTimeOut);
        }
    }

    fetchData() {
        this.setState({
            isFetching: true,
        });

        axiosInstance
            .post(`/logger`, {
                nbResult: this.state.selectedNbResult,
                user: this.state.selectedUtilisateur,
                applicationName: this.state.selectedApplication,
                levels:
                    this.state.selectedNiveau.length === 0
                        ? niveau.map((n) => n.value)
                        : this.state.selectedNiveau,
                after: this.state.selectedAfter,
                before: this.state.selectedBefore,
                context: null,
            })
            .then((responseData) => {
                const response = responseData.data;
                let userList = [];

                response.map(
                    (r) =>
                        userList.indexOf(r.user) === -1 && userList.push(r.user)
                );

                this.setState({
                    data: response,
                    userList: userList.map((user) => {
                        return { label: user, value: user };
                    }),
                });
                this.log = response;
            })
            .catch(() => {
                this.setState({
                    hasError: true,
                });
            })
            .finally(() => {
                this.setState({
                    isFetching: false,
                });
            });
        console.log(this.state);
    }

    putData() {
        this.setState({
            isFetching: true,
        });

        axiosInstance
            .put(`/logger/setLogLevels`, {
                context: this.state.selectedContext,
                applicationNames: this.state.selectedApplicationModal,
                levels: this.state.selectedNiveauModal,
            })
            .catch(() => {
                this.setState({
                    hasError: true,
                });
            })
            .finally(() => {
                this.setState({
                    isFetching: false,
                });
                this.close();
            });
        console.log(this.state);
    }

    onChange(niveau) {
        this.setState({
            selectedNiveau: niveau,
        });
    }

    onChangeLevelModal(niveauModal) {
        this.setState({
            selectedNiveauModal: niveauModal,
        });
    }

    onChangeResult(nbResult) {
        this.setState({
            selectedNbResult: nbResult,
        });
    }

    onChangeUtilisateur(user) {
        this.setState({
            selectedUtilisateur: user,
        });
    }

    onChangeContext(context) {
        this.setState({
            selectedContext: context,
        });
    }

    onChangeApplication(applicationNames) {
        this.setState({
            selectedApplication: applicationNames,
        });
    }

    onChangeApplicationModal(applicationNameModal) {
        this.setState({
            selectedApplicationModal: applicationNameModal,
        });
    }

    onChangeAfter(after) {
        this.setState({
            selectedAfter: after,
        });
    }

    onChangeBefore(before) {
        this.setState({
            selectedBefore: before,
        });
    }

    close() {
        this.setState({ show: false });
    }

    open() {
        this.setState({ show: true });
    }

    render() {
        // The row data is composed into your custom expandable component via the data prop
        //try catch
        const ExpandableComponent = ({ data }) => {
            try {

                let indice = data.message.search('{');
                let slice = data.message.slice(indice);
            
                return <pre>{data.message.slice(0, indice)} {JSON.stringify(JSON.parse(slice), null, '\t')}</pre>
            } catch (e) {
                console.log("Catch error");
                console.log(e);
                return <pre>{data.message}</pre>
            }
        };

        /*const ProgressPending = ({ data }) => {
            const [setPending] = useState(true);
            const [setRows] = useState([]);
            useEffect(() => {
                const timeout = setTimeout(() => {
                    setRows(data);
                    setPending(false);
                }, 2000);
                clearTimeout(timeout);
                return null;
            }, []);
        };*/

        return (
            <div>
                <Horaire></Horaire>

                {this.state.isFetching && <p>Loading...</p>}
                {this.state.hasError && <p>Error!</p>}

                <div className="rs-panel-heading rs-panel-shaded rs-panel-bordered">
                    <h4>Liste des logs
                        &ensp; <IconButton onClick={this.open} icon={<Icon icon="star" />} appearance="primary" />
                    </h4>

                    <div className="modal-container">
                    <Modal show={this.state.show} onHide={this.close}>
                        <Modal.Header>
                            <Modal.Title>Choisir le niveau de log</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Col xs={24} sm={12} md={8}>
                                <p>Contexte :</p>
                                <Input type="text" value={this.state.selectedContext} name="context" onChange={this.onChangeContext}/>
                            </Col>
                            <Col xs={24} sm={12} md={8}>
                                <p>Nom application :</p>
                                <TagPicker
                                    value={this.state.selectedApplicationModal}
                                    data={applicationNameModal}
                                    block
                                    onChange={this.onChangeApplicationModal}
                                />
                            </Col>

                            <Col xs={24} sm={12} md={8}>
                                <p>Niveau :</p>
                                <TagPicker
                                    value={this.state.selectedNiveauModal}
                                    data={niveauModal}
                                    block
                                    onChange={this.onChangeLevelModal}
                                    cleanable
                                />
                            </Col>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={this.putData} appearance="primary">Valider</Button>
                            <Button onClick={this.close} appearance="subtle">Annuler</Button>
                        </Modal.Footer>
                    </Modal>
                    </div>
                    <br />

                    <FlexboxGrid align="middle" justify="center">
                        <FlexboxGrid.Item componentClass={Col} xs={4}>
                            <p>Niveau :</p>
                            <TagPicker
                                value={this.state.selectedNiveau}
                                data={niveau}
                                block
                                onChange={this.onChange}
                            />
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item componentClass={Col} xs={4}>
                            <p>Application :</p>
                            <TagPicker
                                value={this.state.selectedApplication}
                                data={applicationNames}
                                block
                                onChange={this.onChangeApplication}
                            />
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item componentClass={Col} xs={4}>
                            <p>Utilisateur :</p>
                            <InputPicker
                                value={this.state.selectedUtilisateur}
                                data={this.state.userList}
                                block
                                onChange={this.onChangeUtilisateur}
                                searchable
                                cleanable
                            />
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item componentClass={Col} xs={4}>
                            <p>Date Début: </p>
                            <DatePicker
                                format="DD-MM-YYYY HH:mm:ss"
                                value={this.state.selectedAfter}
                                data={this.state.selectedAfter}
                                block
                                onChange={this.onChangeAfter}
                            />
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item componentClass={Col} xs={4}>
                            <p>Date fin:</p>
                            <DatePicker
                                format="DD-MM-YYYY HH:mm:ss"
                                value={this.state.selectedBefore}
                                data={this.state.selectedBefore}
                                block
                                onChange={this.onChangeBefore}
                            />
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item componentClass={Col} xs={2}>
                            <p>Nb résulat :</p>
                            <InputPicker
                                value={this.state.selectedNbResult}
                                data={nbResult}
                                block
                                onChange={this.onChangeResult}
                                //searchable={false}
                                cleanable={false}
                            />
                        </FlexboxGrid.Item>

                        <Button color="green" onClick={this.fetchData}>Filtrer</Button>
                        
                    </FlexboxGrid>
                    <div className="rs-table">
                        <DataTableExtensions
                            data={this.state.data}
                            columns={this.columns}
                            filter={false}
                            print={false}
                            exportHeaders={true}
                        >
                            <DataTable
                                theme="dark"
                                pagination
                                paginationPerPage={25}
                                paginationRowsPerPageOptions={[25, 50, 100]}
                                expandableRows
                                expandOnRowClicked
                                persistTableHead
                                //progressPending={false}
                                // progressComponent={<ProgressPending />}
                                expandableRowsComponent={
                                    <ExpandableComponent />
                                }
                                conditionalRowStyles={conditionalRowStyles}
                                customStyles={customStyles}
                            />
                        </DataTableExtensions>
                    </div>
                </div>
            </div>
        );
    }
}
